import React, { useEffect, useState } from 'react'
import api from '../api'

const Pending = () => {

  const user = localStorage.getItem('user');
  const [padding_data, setPending_Data] = useState([]);

  useEffect(()=>{
    GetPendingData();
  },[])

  const GetPendingData = () =>{
    api.get(`${"http://localhost:5000"}/get/pending/notification/${user}`)
    .then(res =>{
      if(res.data.data){
        setPending_Data(res.data.data);
      }else if(res.data.Logout === "OUT"){
        localStorage.removeItem("ssid");
        window.location.reload()
      }else{

      }
    })
    .catch(error =>{
      console.log(error)
    })
  }
  

  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <h1 className='account-subb-part-01'>Pending</h1>

        <div className='account-pending-main-cnt-01'>

          {padding_data.map((user, i) =>{
            if(user.sub === "pending"){
              return(
                <div key={i} className='account-pending-main-cnt-01-sub-cnt-01'>
                  <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-01'>Request <span style={{color : "orange"}}>sent</span> to claim "<strong style={{color : "green"}}>{user.type === "Money" && <span>₹</span>}{user.title} </strong>". </span><br/>
                  <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-02'>Time : {user.Time}</span>
                </div>
              )
            }
          })}
          
          {/* <div className='account-pending-main-cnt-01-sub-cnt-01'>
            <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-01'>Request <span style={{color : "orange"}}>sent</span> to claim "<strong style={{color : "green"}}>₹15.00</strong>"". </span><br/>
            <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-02'>Time : 08:31</span>
          </div>

          <div className='account-pending-main-cnt-01-sub-cnt-01'>
            <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-01'>Request <span style={{color : "orange"}}>sent</span> to claim "<strong style={{color : "green"}}>₹15.00</strong>"". </span><br/>
            <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-02'>Time : 08:31</span>
          </div>

          <div className='account-pending-main-cnt-01-sub-cnt-01'>
            <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-01'>Request <span style={{color : "orange"}}>sent</span> to claim "<strong style={{color : "green"}}>₹15.00</strong>"". </span><br/>
            <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-02'>Time : 08:31</span>
          </div> */}

        </div>
        <br/>

        <h1 className='account-subb-part-01'>Claimed</h1>

        <div className='account-pending-main-cnt-01'>

        {padding_data.map((user, i) =>{
            if(user.sub === "completed"){
              return(
                <div key={i} className='account-pending-main-cnt-01-sub-cnt-01'>
                  <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-01'><span style={{color : "green"}}>Claimed</span> reward of "<strong style={{color : "green"}}>{user.type === "Money" && <span>₹</span>} {user.title}</strong>". </span><br/>
                  <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-02'>Time : {user.Time}</span>
                </div>
              )
            }
          })}
          
          {/* <div className='account-pending-main-cnt-01-sub-cnt-01'>
            <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-01'><span style={{color : "green"}}>Claimed</span> reward of "<strong style={{color : "green"}}>₹15.00</strong>"". </span><br/>
            <span className='account-pending-main-cnt-01-sub-cnt-01-sub-span-02'>Time : 09:31</span>
          </div> */}

          

          
        </div>
        <div style={{height : "50px"}}>

        </div>
      </center>
    </div>
  )
}

export default Pending
