import React, { useEffect, useState } from 'react'
import api from '../api'

const History = () => {

  const user = localStorage.getItem("user");

  const [data, setData] = useState([]);

  useEffect(()=>{
    Hist()
  },[])

  const Hist = () =>{
    api.get(`${"http://localhost:5000"}/update/data/${user}`)
    .then(res =>{
      if(res.data.data){
        setData(res.data.data);
      }else if(res.data.Logout === "OUT"){
        localStorage.removeItem("ssid");
        window.location.reload()
      }else{

      }
    })
    .catch(error =>{
      console.log(error)
    })
  }

  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <h1 className='account-subb-part-01'>History</h1>
        <div className='account_histor-page-main-cnt-01'>
          {data.map((user, i)=>{
            return(
              <div key={i} className='account_histor-page-main-cnt-01-sub-cnt-01'>
                {user.type === "Credited" && <span className='account_histor-page-main-cnt-01-sub-cnt-01-span-01'>{user.type} "<strong> {user.tp === "Rupee" && <span>₹</span>} {user.rupee} {user.tp === "Stars" && <span>Stars</span>} </strong>" to your Account</span> }
                {user.type === "Debited" && <span className='account_histor-page-main-cnt-01-sub-cnt-01-span-01'>{user.type} "<strong style={{color : "red"}}>{user.tp === "Rupee" && <span>₹</span>} {user.rupee} {user.tp === "Stars" && <span>Stars</span>}</strong>" from your Account</span>}<br/>
                <span className='account_histor-page-main-cnt-01-sub-cnt-01-span-02'>Time : {user.Time}</span>
              </div>
            )
          })}
            {/* <div className='account_histor-page-main-cnt-01-sub-cnt-01'>
                <span className='account_histor-page-main-cnt-01-sub-cnt-01-span-01'>Credited "<strong>₹15.00</strong>" to your Account</span><br/>
                <span className='account_histor-page-main-cnt-01-sub-cnt-01-span-02'>Time : 12:45</span>
            </div>
            <div className='account_histor-page-main-cnt-01-sub-cnt-01'>
                <span className='account_histor-page-main-cnt-01-sub-cnt-01-span-01'>Debited "<strong style={{color : "red"}}>₹15.00</strong>" from your account</span><br/>
                <span className='account_histor-page-main-cnt-01-sub-cnt-01-span-02'>Time : 12:50</span>
            </div> */}
        </div>
      </center>
    </div>
  )
}

export default History
