import React from 'react'
import fb from "../image/fb.png"
import ina from '../image/insta_1.png'
import ln from "../image/in.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Bottom = () => {
    return (
        <center>
            <footer>
                <div className="waves">
                    <div className="wave" id="wave1"></div>
                    <div className="wave" id="wave2"></div>
                    <div className="wave" id="wave3"></div>
                    <div className="wave" id="wave4"></div>
                </div>
                
                <div className='waves-cnt-01'>
                    <strong>sta<span>W</span>ro</strong>
                </div>

                <br/>

                <div className='waves-cnt-02'>

                    <div className='waves-cnt-02-sub-01'>
                        <h1 className='waves-cnt-02-sub-01-h1-01'>Latest Pages</h1><br/>
                        <span onClick={()=>{window.location.href='/about'}}>About</span><br/>
                        <span onClick={()=>{window.location.href='/cupon'}}>Coupons</span><br/>
                        <span onClick={()=>{window.location.href='/signup'}}>Sign-Up</span><br/>
                    </div>

                    {/* <div className='waves-cnt-02-sub-01'>
                        <h1 className='waves-cnt-02-sub-01-h1-01'>Latest Pages</h1><br/>
                        <span onClick={()=>{window.location.href='/about'}}>About</span><br/>
                        <span onClick={()=>{window.location.href='/about'}}>About</span><br/>
                    </div> */}

                    <div className='waves-cnt-02-sub-01'>
                        <h1 className='waves-cnt-02-sub-01-h1-01'>Contact us</h1><br/>

                        <div className='waves-cnt-02-sub-01-sub-01-cnt'>
                            <div className='waves-cnt-02-sub-01-img-cnt-01'>
                                <img src={fb} alt='image' />
                            </div>

                            <div className='waves-cnt-02-sub-01-img-cnt-01'>
                                <img src={ina} alt='image' />
                            </div>

                            <div className='waves-cnt-02-sub-01-img-cnt-01'>
                                <img src={ln} alt='image' />
                            </div>
                        </div>

                        <br/>

                        <div>
                            <span><FontAwesomeIcon icon={faEnvelope} /> <strong>krishnakick1931@gmail.com</strong></span>
                        </div>

                        

                        {/* <span onClick={()=>{window.location.href='/about'}}>About</span><br/>
                        <span onClick={()=>{window.location.href='/about'}}>About</span><br/> */}
                    </div>

                </div>


                <p className='footer_bootom-copy-prin'>&copy;2024 staWro | All Rights Reserved</p>
            </footer>
        </center>
        
      );
}

export default Bottom
