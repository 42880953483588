import React, { useState } from 'react'
import Naviba from './naviba'
import apiAdmin from '../pages/adminapi'

const Questionview = () => {

    const [lang, setLang] = useState([]);
    const [qdata, setQData] = useState([]);



    const GetListsQuest = (e)=>{
        e.preventDefault();
        apiAdmin.get(`${"http://localhost:5000"}/get/admin/all/question/lists/${lang}`)
        .then(res=>{
        if(res.data.data){
            setQData(res.data.data);
        }
        else if(res.data.Logout === "OUT"){
            localStorage.removeItem("token");
            window.location.reload();
        }else{
    
        }
        }).catch(error =>{
        console.log(error);
        alert("Something went Wrong")
        })
    }

  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <br/>
        <h1 className='admin_questionview-h1-01' >Questions List</h1>
    
        <div className='admin_questionview-main-to-btn-01'>
            <button onClick={()=>{window.location.href='/admin/check'}}>View All Questions</button>
        </div>

        <div>
            <form onSubmit={GetListsQuest}>
                <select onChange={e=>{setLang(e.target.value)}} >
                    <option >select</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='English'>English</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Telugu'>Telugu</option>
                </select><br/>
                <button type='submit' className='admin_questionview-btn-01' >Get</button>
            </form>
            <br/>

            <h2 className='admin_questionview-h1-02'>Total Questions : <strong>{qdata.length}</strong> </h2>
            <div>
                {qdata.map((user, i)=>{
                    
                    return(
                        <div className='admin_questions-view-sub-cnt-01'>
                            <h2><strong>{i+1}</strong> : {user.Questio}</h2>
                            <br/>
                            <div className='admin_questions-view-sub-cnt-01-btn-01'>
                                <button className={user.Ans === "a" ? "Admin_QuestionView-Answer" : "admin_questions-view-sub-cnt-01-btn-01-01" } >{user.a}</button>
                                <button className={user.Ans === "b" ? "Admin_QuestionView-Answer" : "admin_questions-view-sub-cnt-01-btn-01-01" } >{user.b}</button>
                            </div>
                            <div className='admin_questions-view-sub-cnt-01-btn-01'>
                                <button className={user.Ans === "c" ? "Admin_QuestionView-Answer" : "admin_questions-view-sub-cnt-01-btn-01-01" }>{user.c}</button>
                                <button className={user.Ans === "d" ? "Admin_QuestionView-Answer" : "admin_questions-view-sub-cnt-01-btn-01-01" } >{user.d}</button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>

      </center>
      <Naviba />
    </div>
  )
}

export default Questionview
