import { faCaretDown, faCaretUp, faChevronDown, faChevronUp, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'

const Payment = () => {

  const [show1, setShow1] = useState(false);

  return (
    <div>
      <center>

        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <h1 className='account-subb-part-01'>Payment</h1>

        <div className='account-payment-main-cnt-01'>
          <span><strong>*</strong> UPI Payments are allowed.</span><br/><br/>
          <span><strong>*</strong> We are not responsible for any transactions made to other UPI accounts.</span><br/><br/>
          <span><strong>*</strong> Users are advised to verify the recipient's details carefully before making any payment.</span><br/><br/>
          <span><strong>*</strong> All transactions are final and cannot be reversed or refunded by us</span>
        </div>
        <br/>

        <div className='account-payment-main-cnt-04'>
          <h3>Enter Paid UTR Number to Add Amount to Account</h3>
          <form>
            <input type='text' placeholder='UTR/Transactions ID' required /><br/>
            <select>
              <option value="" >select ID type</option>
              <option value="UTR" >UTR</option>
              <option value="Transactions ID" >Transactions ID</option>
            </select>
          </form>
        </div>
        <br/>
        <div className='account-payment-main-cnt-05'>
          {show1 ? 
            <FontAwesomeIcon icon={faCaretDown} onClick={()=>{setShow1(false)}} className='account-payment-main-cnt-05-sub-icon-01' />     
            :
            <div onClick={()=>{setShow1(true)}}>
              <FontAwesomeIcon icon={faCaretUp} className='account-payment-main-cnt-05-sub-icon-01' /><br/>
                <strong>Click on me to view payment Option</strong>

            </div>
          }
          
        </div>

        {show1 &&
        <div>
            <hr className='payment-hr-01' />

            <div className='account-payment-main-cnt-02'>
              <span>UPI ID : <strong value="stawro@ybl" id="myInput">stawro@ybl</strong></span><br/>
              <span>Name : <strong>Darshan C</strong></span>
            </div>
            <br />
            <hr className='payment-hr-01' />

            <h1 className='account-payment-h1-01'>QR Scaner</h1>

            <div className='account-payment-main-cnt-03'>
              <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.cnrinstitutes.org%2Fuploads%2Fgallery%2Fmedia%2FCNR-Payment-QR-Code-Full.jpeg&f=1&nofb=1&ipt=abef39f8c3767dda83d15e47fef81a680bafd8ed5828e5bf6a1105faad11c5ea&ipo=images' alt='img' />
            </div>
        </div>}

        

      </center>
      <div style={{height : "50px"}}></div>
    </div>
  )
}

export default Payment
