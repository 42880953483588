import React,{useState, useEffect} from 'react'
import api from './api'

const Carthist = () => {

    const [pending_data, setPending_data] = useState([]);
    const [claimed_data, setClaimed_data] = useState([]);

  const user = localStorage.getItem("user")

  useEffect(()=>{
    GetPending()
    GetClaimed()
  },[])

  const GetClaimed = () =>{
    api.get(`${"http://localhost:5000"}/get/claimed/from/pending/coins/${user}`)
    .then(res =>{
      if(res.data.data){
        setClaimed_data(res.data.data);
      }else if(res.data.Logout === "OUT"){
        localStorage.removeItem("ssid");
        window.location.reload()
      }else{

      }
    })
    .catch(error =>{
      console.log(error)
    })
  }

  const GetPending = () =>{
    api.get(`${"http://localhost:5000"}/get/requested/coins/by/${user}`)
    .then(res =>{
      if(res.data.data){
        setPending_data(res.data.data);
      }else if(res.data.Logout === "OUT"){
        localStorage.removeItem("ssid");
        window.location.reload()
      }else{

      }
    })
    .catch(error =>{
      console.log(error)
    })
  }


  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <h1 className='cart-page-main-h1-01'>Cart History</h1>

        <div className='carthist-cnt-01'>
            <h2>Pending</h2>

            <div className='carthist-cnt-01-sub-cnt-01'>

                {pending_data.map((user, i)=>{
                    return(
                        <div key={i} className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                            <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>{user.title}</strong>
                            <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                                <img src={user.img} alt='img' />
                            </div><br/>
                            <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>{user.valid}</strong></span><br/>
                            <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>{user.stars}</strong></span><br/>
                            <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                                <strong style={{color : "white"}}>Pending.!</strong>
                            </div>
                        </div>
                    )
                })}
                
                {/* <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong style={{color : "white"}}>Pending.!</strong>
                    </div>
                </div> */}

            </div>

        </div>
        <br/>
        <hr style={{width : "70%"}}/>
        <div className='carthist-cnt-01'>
            <h2 style={{color : "green"}}>Claimed</h2>

            <div className='carthist-cnt-01-sub-cnt-01'>

                {claimed_data.map((user, i) =>{
                    return(
                        <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                            <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>{user.title}</strong>
                            <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                                <img src={user.img} alt='img' />
                            </div><br/>
                            <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>{user.valid}</strong></span><br/>
                            <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>{user.stars}</strong></span><br/>
                            <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                                <strong style={{color : "white"}}>Claimed</strong>
                            </div>
                        </div>        
                    )
                })}
                
                {/* <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong style={{color : "white"}}>Claimed</strong>
                    </div>
                </div> */}

            </div>

        </div>

      </center>
    </div>
  )
}

export default Carthist
