import React, { useEffect, useState } from 'react'
import img1 from "../image/img3.jpg"
import api from './api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupee, faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Popup from './popup';

const Play = () => {

  const [balance, setBalance] = useState([]);
  const [btn1, setBtn1] = useState([]);
  const [get_rupe, setGet_Rupee] = useState([]);
  const user = localStorage.getItem("user");
  const [alert, setAlert] = useState(false)
  const [data, setData] = useState([])

  useEffect(()=>{
    GetBalance()
    GetRupeeVal()
  },[])

    const GetRupeeVal = () =>{
        fetch(`${"http://localhost:5000"}/get/rupee/data/play`)
        .then(res => res.json())
        .then(data =>{
            if(data.data){
                setGet_Rupee(data.data)
            }else{

            }
        }).catch(error=>{
            console.log(error)
        })
    }

  const GetBalance = () =>{
    api.get(`${"http://localhost:5000"}/get/acount/balence/${user}`)
    .then(res =>{
      if(res.data.data){
        setBtn1(true)
        setBalance(res.data.data);
      }else if(res.data.Status === "NO"){
        setBtn1(false);
      }else if(res.data.Logout === "OUT"){
        localStorage.removeItem("ssid");
        window.location.reload()
      }else{

      }
    }).catch(error =>{
      console.log(error);
    })
  }

  const New = () =>{
    setAlert(false)
    api.post(`${"http://localhost:5000"}/get/balance/new/data`,{user})
    .then(res=>{
      if(res.data.Status === "OK"){
        GetBalance();
      }else{
        setData("Amoun not Credited")
        setAlert(true)
      }
    }).catch(error =>{
      console.log(error)
    })
  }


  const StartGame = (e) =>{
    setAlert(false)
    e.preventDefault()
    axios.post(`${"http://localhost:5000"}/start/playing/by/debit/amount`, {user, lang : "English"})
    .then(res =>{
      if(res.data.Status === "OK"){
        localStorage.setItem("valid" , "yes")
        GetBalance()
        window.location.href='/start'
      }else if (res.data.Status === "Low-Bal"){
        setData("You not Have Enough Balance")
        setAlert(true)
      }else if(res.data.Status === "BAD"){
        setData("Your turn has ended.")
        setAlert(true)
      }
      else{
        setData("Something Went Wrong Try Again")
        setAlert(true)
      }
    }).catch(error =>{
      console.log(error)
    })

  }

  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <h1 className='play-h1-01'>Start <span>Game</span></h1>
        <div className='play-main-cnt-02'>
          {btn1 &&
            <div>
              <h3>Account Balance</h3>
              <FontAwesomeIcon icon={faIndianRupeeSign} className='play-main-cnt-02-icon-01' />
              <span>{balance.balance}.00</span>
            </div>
          }
          {!btn1 &&
            <button onClick={New} className='btnoon-01'>Get ₹15 Free</button>
          }
          
        </div>
        <div className='play-main-cnt-01' onClick={StartGame}>
            <img src={img1} alt='image' />
            <span>₹ {get_rupe.rupee}.00</span>
        </div>

      </center>
      {alert &&
        <Popup data={data} val={alert} />
      }
    </div>
  )
}

export default Play
