import React, { useEffect, useState } from 'react'
import Naviba from './naviba'
import axios from 'axios'
import apiAdmin from '../pages/adminapi';
import Popup from '../pages/popup';

const Prize = () => {
    const [rupee, setRupee] = useState([]);
    const [get_rupe, setGet_Rupee] = useState([]);
    const [stars_sta, setStars_Data] = useState([]);
    const [stars_count, setStars_count] = useState([]);
    const [stars, setStars] = useState([]);

    const [alert, setAlert] = useState(false);
    const [data, setData] = useState([]);

    useEffect(()=>{
        GetRupeeVal();
        GetStarsData();
    },[])

    const GetStarsData = () =>{
        apiAdmin.get(`${"http://localhost:5000"}/stars/get/all/data/by/stars`)
        .then(res =>{
            if(res.data.data){
                setStars_Data(res.data.data)
            }else if(res.data.Logout === "OUT"){
                localStorage.removeItem("token")
            }
            else{

            }
        })
        .catch(error =>{
            console.log(error)
        })
    }


    const GetRupeeVal = () =>{
        fetch(`${"http://localhost:5000"}/get/rupee/data/play`)
        .then(res => res.json())
        .then(data =>{
            if(data.data){
                setGet_Rupee(data.data)
            }else{

            }
        }).catch(error=>{
            console.log(error)
        })
    }

    const RupeePo = (e) =>{
        e.preventDefault();
        axios.post(`${"http://localhost:5000"}/rupee/get/for/game`,{rupee})
        .then(res=>{
            if(res.data.Status === "OK"){
                GetRupeeVal()
            }else{
                alert("Data Not Posted")
            }
        }).catch(error =>{
            console.log(error)
        })
    }

    const PostorUpdate = async (e) => {
        e.preventDefault();
        axios.post(`${"http://localhost:5000"}/stars/count/one/stars`,{ stars, count: stars_count })
        .then(res =>{
            if(res.data.Status === "OK"){
                GetStarsData()
            }else if(res.data.Logout === "OUT"){
                localStorage.removeItem("token")
            }
            else{
            }
        })
        .catch(error =>{
            console.log(error)
        })   
      };
      


  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <h1 className='admin_prize-h1-01'>Prizes</h1>
        <div className='admin_prize-div-cnt-01'>
            <span className='admin_prize-div-cnt-01-span-01'>Entry Fee: ₹ <strong>{get_rupe.rupee}.00</strong></span>
            
            <form onSubmit={RupeePo} >
                <input type='text' placeholder='Rupee' onChange={e=>{setRupee(e.target.value)}} required /><br/>
                <button type='submit'>Post</button>
            </form>
        
        </div>

        <br/>

        <div className='admin_prize-div-cnt-02'>

            {stars_sta.map((user, i)=>{

                return(
                    <form style={{margin : "10px"}} key={i} onSubmit={PostorUpdate}>
                        <strong>{user.count}</strong><br/>
                        <input type='text' placeholder={`${user.stars} stars`} onChange={e=>{setStars_count(e.target.value);setStars(user.stars)}} required /><br/>
                        <button type='submit'>post</button>
                    </form>
                )
            })}
            <br/>

            {/* <form onSubmit={PostorUpdate}>
                <input type='text' placeholder='Add stars' onChange={e=>{setStars_count(e.target.value)}} required /><br/>
                <select onChange={e=>{setStars(e.target.value)}} required>
                    <option>select new stars</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
                <button type='submit'>post</button>

            </form><br/> */}


        </div>


      </center>
      <Naviba />
      {alert &&
        <Popup data={data} val={alert} />
      }

    </div>
  )
}

export default Prize
